import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import AffiliateProgramHeroSection from '@sections/affiliate-program/AffiliateProgramHeroSection'
import AffiliateProgramBenefitSection from '@sections/affiliate-program/AffiliateProgramBenefitSection'
import SectionDivider from '@components/common/SectionDivider'
import ReadySection from '@sections/common/ReadySection'

const AffiliateProgram = ({ intl }) => {
  return (
    <Layout pageName="affiliate-program">
      <AffiliateProgramHeroSection />
      <AffiliateProgramBenefitSection />
      <SectionDivider hidePaddingBottom />
      <ReadySection
        title={intl.formatMessage({ id: 'affiliateProgram.becomePartner.title' })}
        description={intl.formatMessage({ id: 'affiliateProgram.becomePartner.description' })}
        buttonText={intl.formatMessage({ id: 'affiliateProgram.becomePartner.buttonText' })}
        href="https://ideabuddy.getrewardful.com/signup"
        descriptionMaxWidth={'70%'}
      />
    </Layout>
  )
}

export default injectIntl(AffiliateProgram)
