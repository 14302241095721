import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import TextImageHero from '@components/common/TextImageHero'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonLink from '@components/ui/buttons/ButtonLink'

const AffiliateProgramHeroSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <TextImageHero
        title={intl.formatMessage({ id: 'affiliateProgram.hero.title' })}
        description={[intl.formatMessage({ id: 'affiliateProgram.hero.description.text01' })]}
        image={<StaticImage alt="hero" src="../../assets/images/affiliateProgram/hero.png" width={580} />}
        buttonAlignSm={'center'}
        button={
          <ButtonLink
            external
            href="https://ideabuddy.getrewardful.com/signup"
            buttonText={intl.formatMessage({ id: 'affiliateProgram.hero.buttonText' })}
            mdfontsize={'16px'}
          />
        }
      />
    </SectionWrapper>
  )
}

export default injectIntl(AffiliateProgramHeroSection)
